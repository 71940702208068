import React from 'react'
import Layout from "../gatsby-theme-blog/components/layout"
import Footer from "../custom-components/footer"

export default ()=> {
return (

<Layout>
<div className="md:max-w-screen-md mx-auto my-5 container leading-normal">

<h1 className="my-3 text-xl">
Cookie Policy
</h1>    
<h2 className="mt-3 mb-2 text-lg">
Cookies
</h2>
<p>
Our Privacy Policy explains our principles when it comes to the collection, processing,
 and storage of your information. The Cookie Policy specifically explains how we and users of our services deploy cookies, 
 as well as the options you have to control them.
</p>

<h2 className="mt-3 mb-2 text-lg">
What are cookies?
</h2>
<p>
Cookies are small pieces of data, stored in text files, that are stored on your computer or
 other device when websites are loaded in a browser. They are widely used to ‘remember’ you
  and your preferences, either for a single visit (through a ‘session cookie’) or for multiple
   repeat visits (using a ‘persistent cookie’). They ensure a consistent and efficient experience 
   for visitors, and perform essential functions such as remember users choices. 
   Cookies may be set by the site that you are visiting (known as ‘first party cookies’), or by third parties,
   such as those who serve content or provide advertising or analytics services on the website (‘third party cookies’).
</p>

<h2 className="mt-3 mb-2 text-lg">
Cookies set by WordPress.org
</h2>
<p>
We use cookies for a number of different purposes. Some cookies are necessary for technical reasons;
 some enable a personalized experience for visitors; and some allow the display of advertising from 
 selected third party networks. Some of these cookies may be set when a page is loaded, or when a visitor 
 takes a particular action (clicking the ‘like’ or ‘follow’ button on a post, for example).
</p>

<p>
Below the different categories of cookies set by geekynut.com are outlined.
</p>

<p>
Strictly Necessary: These are the cookies that are essential for geekynut.com to perform basic functions.
These include those required to allow registered users to authenticate and perform account related functions.
</p>
<p>
Functionality: These cookies are used to store preferences set by users such as account name, language, and location.
</p>
<p>
Performance: Performance cookies collect information on how users interact with our website geekynut.com, including what pages are visited most, 
as well as other analytical data. These details are only used to improve how the website functions.
</p>

<p>
Tracking: These are set by trusted third party networks (e.g. Google Analytics) to track details such as the number of unique visitors,
 and pageviews to help improve the user experience.
</p>

<p>
Third Party/Embedded Content: geekynut.com makes use of different third party applications and services to enhance the
 experience of website visitors. These include social media platforms such as Facebook and Twitter (through the use of sharing buttons),
  or embedded content from YouTube. As a result, cookies may be set by these third parties, and used by them to track your online activity.
   We have no direct control over the information that is collected by these cookies.
</p>

<p>Geekynut.com</p>

<h2 className="mt-3 mb-2 text-lg">
Cookie	Duration	
</h2>
<p>
Google Analitics	Browser default (2 year)
Terms and condition agrement Browser default (2 year)
</p>

<h2 className="mt-3 mb-2 text-lg">
Controlling Cookies
</h2>
<p>
Visitors may wish to restrict the use of cookies, or completely prevent them from being set.
Most browsers provide for ways to control cookie behavior such as the length of time they are stored — 
either through built-in functionality or by utilizing third party plugins.
</p>

<p>
Some specific opt-out programs are available here:
</p>

<p>
Google Analytics - <a href="https://tools.google.com/dlpage/gaoptout">
https://tools.google.com/dlpage/gaoptout
</a>
</p>

<p>
It’s important to note that restricting or disabling the use of cookies can limit the functionality of sites, or prevent them from working correctly at all.
</p>

</div>

<Footer />
</Layout>)}